import React from "react"
import { AboutConfig } from "../../config/config-data"


const About = ({ GradientAnimation }) => (

  <section id={AboutConfig.section_header}>
    {AboutConfig.section_header &&
    <div id="about_1662791249357" className={`Heading_Text ${!GradientAnimation ? "Heading_Gradient" : ""}`}>{AboutConfig.section_header}</div>
    }
    {AboutConfig.section_sub_header &&
    <div id="about_1662791251998" className="Normal_Text" style={{paddingBottom:"var(--Text_Size_Five)"}}>{AboutConfig.section_sub_header}</div>
    }
    <div id="about_1662791256718" className="Card Card_Container">
      {AboutConfig.about_image ?
      <div id="about_1662791258982" style={{alignItems:"center"}} className="Flex_Container">
        <div id="about_1662791260415" style={{flex: "clamp(30vh, 30%, 10000px)"}}>
          <img id="about_1662791261733" src={AboutConfig.about_image} style={{borderRadius:"2vmin", width:AboutConfig.about_image_stretch_width, height:"auto"}} height={AboutConfig.about_image_height} width={AboutConfig.about_image_width} loading="lazy" alt="About Image" />
        </div>
        <div id="about_1662791263415" style={{flex: "clamp(50vh, 70%, 10000px)"}}>
          <div id="about_1662791266470" className="Normal_Text" style={{fontWeight:"bold", margin: "2vmin"}}>{AboutConfig.about_text}</div>
        </div>
      </div>
      :
      <div id="about_1662791273317" className="Normal_Text" style={{fontWeight:"bold"}}>{AboutConfig.about_text}</div>
      }
    </div>
  </section>
);

export default About